














































import EventsDisplay from '../components/EventsDisplay.vue'
import Vue from "vue";
import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  components: {
    EventsDisplay
  },
  computed: {
    ...mapState([
      'activeTab',
      'myFeedEvents',
      'myFeedSort'
    ])
  }
})
export default class Feed extends Vue {

  private loading = true

  private async created () {
    await this.$store.dispatch('loadMyFeedEvents')
    this.loading = false
  }

  private async loadMore () {
    this.loading = true
    await this.$store.dispatch('loadMyFeedEvents')
    this.loading = false
  }

  private async setSort (val) {
    this.loading = true
    await this.$store.dispatch('sortMyFeed', val)
    this.loading = false
  }

}
